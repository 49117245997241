<!--控制器管理-->
<template>
  <div id="controllerManagement" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <div class="header">
      <div class="header-left">
        <div class="header-left-cascader">
          <img src="../../../../public/images/sidebar/home_normal.png" style=" width: 35px; height: 35px;"/>
          <span style="padding-left: 10px;padding-right: 10px">{{$t('controller.a2')}}</span>
          <a-cascader id="cascader" :options="options" @change="onChange" :placeholder="$t('controller.a2')" style="width:360px;" />
        </div>
        <div class="header-title" v-if="siteName !== ''">
          <img src="../../../../public/images/sidebar/home_normal.png"/>
          <span>{{ siteName }}</span>
        </div>
      </div>
      <div class="header-right">
        <!--<a-button  style="margin-right: 15px"  type="primary"  @click="confirm" :disabled="disabled">{{$t('controller.a23')}}</a-button>-->
        <a-button type="primary" @click="confirm">{{$t('controller.a3')}}</a-button>
      </div>
    </div>
    <div class="main">
      <!--<div class="terminalInfo">-->
        <!--<headertitle style="padding:0px 20px" v-if="list.length !== 0">{{terminalInfo}}</headertitle>-->
        <!--<terminalCard-->
          <!--v-for="(item,value) in list"-->
          <!--:key="value"-->
          <!--:siteId="siteId"-->
          <!--:cardContent="item"-->
          <!--@refresh="refresh"-->
          <!--&gt;</terminalCard>-->
      <!--</div>-->
      <!-- <div class="deviceInfo">
        <headertitle>{{deviceInfo}}</headertitle>
        <deviceInfoTable></deviceInfoTable>
      </div> -->
      <div class="runStatus">
        <headertitle style="padding:0px 20px">{{runStatus}}</headertitle>
        <runStatusTable @refresh="refresh" :dataList="detailList"></runStatusTable>
      </div>

      <div>
        <headertitle style="padding:0px 20px">{{smartGatewayValueInfo}}</headertitle>
        <smart-gateway-info @refresh="refresh"  :smartGatewayValueList="smartGatewayInfoList"></smart-gateway-info>
      </div>

      <!-- <div class="statusSend">
        <headertitle>{{statusSend}}</headertitle>
        <statusSendTable></statusSendTable>
      </div> -->
    </div>
  </div>
</template>

<script>
import deviceInfoTable from "./deviceInfoTable";
import runStatusTable from "./runStatusTable";
import statusSendTable from "./statusSendTable";
import terminalCard from "./terminalCard";
import headerTitle from "../../../components/Title/headerTitleA";
import smartGatewayInfo from "./smartGatewayInfo";

import {getFLCList,getFLCInfo,getFLCPresentStatus} from "../../../api/sysmanage";

export default {
  name: "controllerManagement",
  data() {
    return {
      siteName:"",
      terminalInfo:this.$t('controller.a4'),
      deviceInfo:this.$t('controller.a5'),
      runStatus:this.$t('controller.a6'),
      statusSend:this.$t('controller.a7'),
      smartGatewayValueInfo: this.$t('controller.a30'),
      baseInfo:this.$store.getters.oneMenu,
      siteId:0,
      loading:false,
      disabled:true,
      list:[
        // {
        //   checkdate: "2021/01/07",
        //   code: "F0001013",
        //   ctrlname: "GWC",
        //   deviceid: 0,
        //   gwcid: 0,
        //   id: 0,
        //   key: 1,
        //   linetype: "10.96.143.29",
        //   person: "99999",
        //   sitename: "测试站点名",
        //   startstop: "运行",
        // },
      ],
      detailList:[],
      smartGatewayInfoList:[],
    };
  },
computed: {
    options() {
      let siteGrList = [];
      let siteList=[];
      let options = [];
      this.baseInfo = this.$store.getters.oneMenu;
      console.log("controllerManagement baseInfo:", this.baseInfo);
      if(this.baseInfo!=null &&this.baseInfo.length>0){
        for(let i=0;i<this.baseInfo.length;i++){
          let client = {
                    value: this.baseInfo[i].id,
                    label: this.baseInfo[i].clientName,
                    children: [],
                  };
          options.push(client);
          siteGrList = this.baseInfo[i].siteGrList;
          if(siteGrList!=null&&siteGrList.length>0){
            for(let j=0;j<siteGrList.length;j++){
              let siteGr = {
                    value: siteGrList[j].id,
                    label: siteGrList[j].name,
                    children: []
                  };
              client.children.push(siteGr);
              siteList= siteGrList[j].siteList;
              if(siteList!=null&&siteList.length>0){
                for(let k=0;k<siteList.length;k++){
                    if(siteList[k]!=null){
                      let site = {
                        value: siteList[k].siteServiceTypeID+","+siteList[k].id,
                        label: siteList[k].name,
                        children: []
                      };
                      siteGr.children.push(site);
                    }
                }
              }
            }
          }
        }
      }
      console.log("controllerManagement options:", options);
      return options;
    },
  },
  mounted() {
    // 调用获取一级导航栏菜单的方法
    this.getlist();
  },
  methods:{
    // 初始化三级导航栏
    getlist() {
      this.$store.dispatch("menu/getlist");
    },
    refresh(msg){
      // this.inquire();
        this.getFLCPresentDeviceStatus();
    },
    onChange(value,selectedOptions) {
      if(selectedOptions!=null){
        console.log("select selectedOptions:",selectedOptions);
        if(selectedOptions.length>1){
            this.siteName=selectedOptions[1].label+' / '+selectedOptions[2].label;
        }
          if(value!=null){
              if(value.length>1){
                  let arr = value[2].split(',');
                  // this.siteServiceTypeID = arr[0]-0;
                  this.siteId = arr[1]-0;
                  console.log("siteId:",this.siteId);
                  this.disabled=false;
                  // this.inquire();
                  this.getFLCPresentDeviceStatus();
              }
          }
      }else {
          this.siteId=0;
          this.disabled=true;
      }
    },
    // 条件查询方法
    inquire() {
      console.log("controllerManagement siteId:", this.siteId);
      this.loading = true;

      if(this.list.length > 0){
          this.list=[];
      }

      if(this.detailList.length > 0){
          this.detailList=[];
      }


      getFLCList(this.siteId)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          if(data!=null && data.length>0){
            this.list.length=0;
            this.detailList.length=0;
            this.smartGatewayInfoList.length=0;
            for (let i = 0; i < data.length; i++) {
              //加序号
              data[i].key = data[i].id;
              if(data[i].deviceid==0){//初始化数据 设置默认值
                  this.smartGatewayInfoList =data[i].smartGateWayValueInfoVos;
              }
            }
            //初始化默认数据
            if(this.smartGatewayInfoList.length > 0){
                for (let i = 0; i < this.smartGatewayInfoList.length; i++) {
                    this.smartGatewayInfoList[i].editable=false;
                    if(this.smartGatewayInfoList[i].skip == -1){
                        this.smartGatewayInfoList[i].readSkip=this.$t('controller.a31');
                    }else {
                        this.smartGatewayInfoList[i].readSkip=this.$t('controller.a32');
                    }
                     //初始化lastId值
                     if (this.smartGatewayInfoList[i].lastId == null) {
                         this.smartGatewayInfoList[i].lastId ="--";
                     }
                }
            }
            this.detailList=data;
            this.list = data;
            console.log("controllerManagement data", data);
          }else {
              if(this.siteName!=='')
                  this.siteName=""
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    getFLCInfo(flc){
      let par={
        siteId:this.siteId,
        gwcId:flc.gwcid,
        deviceId:flc.deviceid,
      }
      getFLCInfo(par)
      .then((res) => {
          let { data } = res;
          console.log("getFLCInfo data", data);
          if(data!=null){
            flc.detail=data;
            data.key = this.detailList.length;
            this.detailList.push(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getFLCPresentDeviceStatus() {
        let partemp = {
            siteId: this.siteId,
        }
        this.loading = true;
        getFLCPresentStatus(partemp).then((res) => {
            this.loading = false;
            this.inquire();
        })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
    },

    confirm() {
        this.$confirm({

            title: this.$t('controller.a23'),
            content: this.$t('controller.a24'),
            okText: this.$t('controller.a21'),
            cancelText: this.$t('controller.a22'),
            centered:true,
            onOk: () =>  {
              this.getFLCPresentDeviceStatus();
            },

        });
    },
  },

  components: {
    "deviceInfoTable":deviceInfoTable,
    "runStatusTable":runStatusTable,
    "statusSendTable":statusSendTable,
    "headertitle": headerTitle,
    "terminalCard":terminalCard,
    "smartGatewayInfo":smartGatewayInfo,
  },
};
</script>

<style scoped>
#controllerManagement {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.header{
  display: flex;
  padding: 20px 20px 15px 20px;
  justify-content: space-between;
  font-size: 18px;
}
.header-left{
  display: flex;
}
.header-left-cascader{
  padding-left: 10px;
  margin-right: 30px;
}
.header-title{
  display: flex;
  align-items: center;
}
.header-title img{
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.title {
  width: 100%;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.main {
  height: calc(100% - 70px);
  overflow: auto;
}
.terminalInfo{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
}

</style>