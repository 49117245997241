<!--站点的读取记录频率读取设置 rowKey="(record) => record.objectKey"-->
<template>
  <div id="smartGatewayInfo">

      <a-table
           :columns="columns"
           :dataSource="getSmartGatewayData"
           :pagination="false"
           :rowKey="(record) => record.objectKey"
           :scroll="{ x: 1000 }"
           bordered
      >
          <template slot="readSkip" slot-scope="text, record, index">
             <span style="color: green;font-weight: bold" v-if="record.skip > -1" >
                {{$t('controller.a32')}}
             </span>
              <span style="color: red;font-weight: bold" v-else>
                {{$t('controller.a31')}}
              </span>
          </template>


          <template
                  v-for="col in ['skip', 'lastId']"
                  :slot="col"
                  slot-scope="text, record, index"
          >
              <div :key="col">
                  <a-input
                          v-if="record.editable"
                          style="margin: -5px 0"
                          :value="text"
                          @change="e => handleChange(e.target.value, record.objectKey, col)"
                  />
                  <template v-else>
                      {{ text }}
                  </template>
              </div>
          </template>
          <template slot="operation" slot-scope="text, record, index">
              <div class="editable-row-operations">
              <span  v-if="record.editable">
                  <a @click="() => cancel(record)"> {{$t('controller.a35')}}</a>
                  <a-popconfirm :title="popconfirmTile" @confirm="() => save(record)">
                    <a>{{$t('controller.a33')}}</a>
                  </a-popconfirm>
              </span>
               <span v-else>
                  <a :disabled="editingKey !== ''" @click="() => edit(record)">{{$t('controller.a34')}}</a>
               </span>
              </div>
          </template>
      </a-table>
  </div>
</template>

<script>
    import {updateControlHistoryRead} from "../../../api/sysmanage";
    export default {
        name: "smartGatewayInfo",
        props: {
            smartGatewayValueList: {
                type: Array,
            },
        },
        data() {
            return{
                popconfirmTile:this.$t('controller.a45'),
                smartGatewayData:[],
                cacheSkip:'',
                cacheLastId:'',
                editingKey:'',
                columns: [
                    {
                        title: this.$t('controller.a36'),
                        dataIndex: "name",
                        ellipsis: true,
                        width: 130,
                    },
                    {
                        title: this.$t('controller.a37'),
                        dataIndex: "readSkip",
                        scopedSlots: { customRender: 'readSkip' },
                        ellipsis: true,
                        align: "center",
                        width: 100,
                    },
                    {
                        title: this.$t('controller.a38'),
                        dataIndex: "skip",
                        scopedSlots: { customRender: 'skip' },
                        width: 100,
                        ellipsis: true,
                        align: "center",
                    },
                    {
                        title: "LAST ID",
                        dataIndex: "lastId",
                        width: 60,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: { customRender: 'lastId' },
                    },
                    {
                        title: this.$t('controller.a39'),
                        dataIndex: "lastExecute",
                        width: 80,
                        ellipsis: true,
                        align: "center",
                        customRender: (text, record, index) => {
                            if (record.lastExecute == "") {
                                return "--";
                            } else {
                                return record.lastExecute;
                            }
                        },
                    },
                    {
                        title: this.$t('controller.a40'),
                        dataIndex: 'operation',
                        ellipsis: true,
                        align: "center",
                        width: 80,
                        scopedSlots: { customRender: 'operation' },
                    },
                ],
            }
        },

        computed: {
            getSmartGatewayData () {
                for(let i=0;i<this.smartGatewayValueList.length;i++){
                    if(this.smartGatewayData.length ==this.smartGatewayValueList.length){
                        if(!this.smartGatewayData[i].editable){
                            this.smartGatewayData[i]=this.smartGatewayValueList[i];
                        }
                    }else{
                         this.smartGatewayData.push(this.smartGatewayValueList[i]);
                         this.cacheSkip='';
                         this.cacheLastId='';
                    }
                }
                return this.smartGatewayData;
            }
        },

        methods:{
            //输入框的逻辑处理
            handleChange(value, key, column) {
                const newData = [...this.smartGatewayValueList];
                const target = newData.find(item => key === item.objectKey);
                if (target) {
                    target[column] = value;
                    this.smartGatewayData = newData;
                }
            },
            //设置按钮的逻辑处理
            edit(record) {
                const newData = [...this.smartGatewayValueList];
                const target = newData.find(item => record.objectKey === item.objectKey);
                this.editingKey = record.objectKey;
                this.cacheSkip=record.skip;
                this.cacheLastId=record.lastId;
                if (target) {
                    target.editable = true;
                    this.smartGatewayData = newData;
                }

            },
            //保存设置数据的逻辑处理
            save(record) {
                if(isNaN(parseInt(record.skip))){
                    this.$error({
                        title: this.$t('controller.a41'),
                        content:this.$t('controller.a42'),
                        centered: true,
                    });
                    return;
                }
                if(record.lastId!=="--"){
                    if(isNaN(parseInt(record.lastId)) || parseInt(record.lastId) < 0 ){
                        this.$error({
                            title: this.$t('controller.a43'),
                            content:this.$t('controller.a44'),
                            centered: true,
                        });
                        return;
                    }
                }
                this.updateControlHistoryReadSet(record);

            },
            //取消逻辑处理
            cancel(record) {
                const newData = [...this.smartGatewayValueList];
                const target = newData.find(item => record.objectKey === item.objectKey);
                this.editingKey = '';
                if (target) {
                    Object.assign(target, this.smartGatewayValueList.find(item => record.objectKey === item.objectKey));
                    target.editable=false;
                    target.skip= this.cacheSkip;
                    target.lastId=this.cacheLastId;
                    this.smartGatewayData = newData;
                    this.cacheSkip='';
                    this.cacheLastId='';
                }
            },

            updateControlHistoryReadSet(record){
                var lastIdtemp=-1;
                if(record.lastId!="--"){
                    lastIdtemp=parseInt(record.lastId);
                }
                let par = {
                    gwcId: record.gwcId,
                    objectKey: record.objectKey,
                    skip: parseInt(record.skip),
                    lastId:lastIdtemp,
                };
                this.loading = true;
                updateControlHistoryRead(par)
                    .then((res) => {
                        if (res.errorCode=='00') {
                            this.$message.info(this.$t('controller.a16'));
                            const newData = [...this.smartGatewayValueList];
                            const target = newData.find(item => record.objectKey === item.objectKey);
                            this.editingKey = '';
                            if (target) {
                                target.editable = false;
                                this.smartGatewayData = newData;
                            }
                            //重新获取最新数据
                            this.$emit('refresh',"");
                        }else{
                            this.$error({
                                title: res.msg,
                                content: res.msg,
                                centered: true,
                            }
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                this.loading = false;
            }

        }


    }

</script>

<style scoped>
    #smartGatewayInfo {
        width: 1400px;
        padding: 0px 10% 20px 20px;
        margin-bottom: 40px;
        white-space: nowrap;
        overflow: hidden;
    }
    table {
        width: 100%;
        border-left: 1px solid #dedfdf;
        border-right: 1px solid #dedfdf;
    }
    .editable-row-operations a {
        margin-right: 8px;
    }
</style>