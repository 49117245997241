<!--公共模块--表格组件-->
<template>
  <div id="deviceInfoTable" >
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      :scroll="{ x: 1300 }"
    >
    </a-table>
  </div>
</template>

<script>
export default {
  name: "deviceInfoTable",
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      pagination: {
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `${total + this.$t("controllIntermittent.a10")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          title: "终端",
          dataIndex: "key",
          ellipsis: true,
          width: 70,
          fixed: "left",
        },
        {
          title: "设备名称",
          dataIndex: "gr1",
          width: 150,
          ellipsis: true,
        },
        {
          title: "是否需要上门",
          dataIndex: "gr2",
          width: 150,
          ellipsis: true,
        },
        {
          title: "异常检出",
          dataIndex: "gr3",
          width: 130,
          ellipsis: true,
        },
        {
          title: "恢复检出",
          dataIndex: "dvName",
          width: 130,
          ellipsis: true,
        },        
      ],
    };
  },
};
</script>
<style scoped>
#deviceInfoTable {
  width: 100%;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-left: 1px solid #dedfdf;  
  border-right: 1px solid #dedfdf;
}
</style>