<template>
  <div id="terminalCard" @click="goDetails">
    <div class="left">
      <div class="ctrlName">
        <span>{{cardContent.ctrlname}}</span>
        <!-- <a-icon type="form" @click="onUpdateClick"/>  -->
      </div>
      <div class="siteName">
        <span>{{cardContent.code}}  </span>
        <span>{{cardContent.sitename}}</span>
      </div>

      <div class="ctrlType">
        <!--<span>{{ctrlType}}</span>-->
        <span>{{this.$t('details.a76')}} : </span>
        <span   class="runSpan" v-bind:style="styleObject" >{{startstop}}</span>

      </div>
      <div class="ctrlType">
        <!--<span>{{ctrlType}}</span>-->
        <span>{{this.$t('edge.edge018')}} : </span>
        <span   class="runSpan" v-bind:style="statusObject" >{{deviceStatus}}</span>
      </div>
    </div>
    <div class="right">
      <div class="imgEdit">
        <img
          src="../../../../public/images/set/edit01.png"
          @click="onUpdateClick"
        />
      </div>
      <!--<div class="status" v-bind:style="styleObject">-->
        <!--<span>{{startstop}}</span>-->
      <!--</div>-->
    </div>
    <a-modal
      :title="title"
      :width="600"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      centered
      :destroyOnClose="true"
      v-drag
    >
      <div class="form_row">
        <div class="form_col">
          <a-form-model-item :label="$t('controller.a8')">
            <a-select v-model="form.sysStat" style="width: 120px">
              <a-select-option v-for="item in options" :value="item.key" :key="item.key">
                {{item.desc}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </div>
      </div>
      <div class="form_row">
        <div class="form_col">
          <a-form-model-item :label="$t('controller.a9')" prop="date1">
            <a-textarea v-model= "form.comment" :rows="6" />
          </a-form-model-item>
          </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {updateFLC} from "../../../api/sysmanage";

export default {
  props: {
    siteId:'',
    cardContent: {
      type: Object,
    },
  },
  data() {
    return {
      name: "terminalCard",
      title:this.$t('controller.a10'),
      visible:false,
      options:[{key:0,desc:this.$t('controller.a12')},{key:1,desc:this.$t('controller.a13')},{key:2,desc:this.$t('controller.a14')},{key:3,desc:this.$t('controller.a15')}],
      form:{
        sysStat:0,
        person:JSON.parse(this.$store.getters.user).id,
        comment:"",
      },
      loading:false,
      styleObject:{
        color: 'red',
      },
      statusObject:{
          color: 'red',
      },
    }
  },
  mounted(){
    this.changeColor(this.cardContent.startstop);
    this.changeStatusColor(this.cardContent.deviceStatus);
  },
  computed:{
    deviceStatus:function () {
        switch (this.cardContent.deviceStatus) {
            case this.$t('controller.a18'):
                this.statusObject.color='lightgreen';
                break;
            case this.$t('controller.a19'):
                this.statusObject.color='red';
                break;
            case this.$t('controller.a20'):
                this.statusObject.color='red';
                break;
        }
        return this.cardContent.deviceStatus;
      },

    startstop: function() {
      return this.cardContent.startstop;
    }
  },
  watch: {
    startstop: {
      handler: function(val,oldVal) {
        //do something
        this.changeColor(val);
      }
    },
  },
  methods: {
    changeColor(val){
        switch (val) {
        case this.$t('controller.a12'):
          this.styleObject.color='gray';
          break;
        case this.$t('controller.a13'):
          this.styleObject.color='blue';
          break;
        case this.$t('controller.a14'):
          this.styleObject.color='lightgreen';
          break;
        case this.$t('controller.a15'):
          this.styleObject.color='red';
          break;
      }
    },
      changeStatusColor(val){
          switch (val) {
              case 0:
                  this.statusObject.color='lightgreen';
                  break;
              case 1:
                  this.statusObject.color='red';
                  break;
              case 5:
                  this.statusObject.color='red';
                  break;
          }
      },
    updateFLC(){
      let par={
        deviceId:this.cardContent.deviceid,
        gwcId:this.cardContent.gwcid,
        sysStat:this.form.sysStat,
        person:this.form.person+"",
        comment:this.form.comment,
      };

      console.log("updateFLC par:",par);
      updateFLC(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          console.log("updateFLC data:",data);
          if(data){
            this.$message.info(this.$t('controller.a16'));
            this.$emit('refresh',"");
          } else{
            this.$message.error(this.$t('controller.a17'));
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(this.$t('controller.a17'));
          console.log(err);
        });
    },
    onUpdateClick(){
      this.visible=true;
      console.log("updateFLC cardContent:",this.cardContent);
      // console.log("updateFLC person:",this.form.person);
    },
    handleOk(){
      this.visible=false;
      this.updateFLC();
    },
    handleCancel(){
      this.visible = false;
    },
    goDetails() {
    },
  },

};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#terminalCard {
  width: 240px;
  height: 120px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin: 0 auto 15px auto;
  border: 1px solid #ffffff;
  transition: 200ms;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:flex-start;
  border: 1px solid #7682ce
}
#terminalCard:hover {
  background-color: #7682ce;

}
.left{
  width:80%;
}
.runSpan{
  padding-left: 10px;
  font-size: 13px;
  font-weight: bold;
}
.ctrlName{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 7px;
  font-size: 17px;
  color:#00c;
}
.siteName{
    padding-left: 10px;
    font-size: 14px;
    padding-bottom: 5px;
}
.ctrlType{
  padding-left: 10px;
  font-size: 10px;
  padding-bottom: 5px;
}

.right{
  width:20%;
  /* display: flex;
  align-items: flex-end; */
}
.imgEdit{
  padding-top:10px;
}
.imgEdit img{
  cursor: pointer;
}
.status{
  /*padding-top: 5px;*/
  padding-right: 10px;
  /* color:#0c0; */
}
.line{
  border-bottom: 1px solid #cccdcd;
}
</style>