<!--公共模块--表格组件-->
<template>
      <div id="runStatusTable" >
            <a-table
                    :row-selection="rowSelection"
                    :columns="columns"
                    :dataSource="dataList"
                    :pagination="false"
                    :scroll="{ y: 500 }"
            >

     <span slot="deviceStatus" slot-scope="text,record">
           <span v-if="text==0" style="color: green;font-weight: bold">{{$t('controller.a18')}}</span>
           <span v-else-if="text==1" style="color: red;font-weight: bold">{{$t('controller.a19')}}</span>
           <span v-else style="color: #eb2f96;font-weight: bold">{{$t('controller.a20')}}</span>
     </span>

         <span slot="startstop" slot-scope="text,record">
         <a-select style="width: 60%;" v-model="record.startstop" :default-value="record.startstop">
             <a-select-option  :value="2">
              <span style="color: green ;font-weight: bold">{{$t('controller.a14')}}</span>
             </a-select-option>
             <a-select-option  :value="0">
               <span style="color: gray;font-weight: bold">{{$t('controller.a12')}}</span>
             </a-select-option>
             <a-select-option  :value="1">
               <span style="color:blue;font-weight: bold">{{$t('controller.a13')}}</span>
             </a-select-option>
             <a-select-option  :value="5">
                <span style="color: red;font-weight: bold">{{$t('controller.a15')}}</span>
             </a-select-option>
         </a-select>

     </span>

            </a-table>
            <div style="padding: 15px">
                <a-button type="primary"   :disabled="this.hasSelected" @click="updateFLC" >{{$t('controller.a10')}}</a-button>
            </div>
        </div>
</template>

<script>
import {updateFLC} from "../../../api/sysmanage";
export default {
  name: "runStatusTable",
  props: {
    dataList: {
      type: Array,
    },
  },
  data() {
    return {
        styleObject:{
            color: 'red',
        },
      selectRowsData:[],
      columns: [
        {
          title: this.$t('controller.a25'),
          dataIndex: "ctrlname",
          ellipsis: true,
          width: 30,
          fixed: "left",
        },
        // {
        //   title: "端子NO",
        //   dataIndex: "gr1",
        //   width: 150,
        //   ellipsis: true,
        // },
        {
          title: this.$t('controller.a26'),
          dataIndex: "code",
          width: 80,
          ellipsis: true,
        },
        {
          title: this.$t('controller.a27'),
          dataIndex: "deviceStatus",
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'deviceStatus' },
        },
        // {
        //   title: "错误详情",
        //   dataIndex: "dvName",
        //   width: 130,
        //   ellipsis: true,
        // },
        {
          title: this.$t('controller.a8'),
          dataIndex: "startstop",
          width: 100,
          ellipsis: true,
          scopedSlots: { customRender: 'startstop' },
        },
        {
          title: this.$t('controller.a28'),
          dataIndex: "checkdate",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('controller.a29'),
          dataIndex: "person",
          width: 70,
          ellipsis: true,
        },
        {
          title: this.$t('controller.a9'),
          dataIndex: "comment",
          width: 190,
          ellipsis: true,
        },
      ],
    };
  },
  computed: {
          rowSelection(){
              return {
                  onChange: (selectedRowKeys) => {
                      this.selectRowsData=selectedRowKeys;
                  }
              }
          },

      hasSelected() {
          let lasRes = [];
          for (let j = 0; j < this.dataList.length; j++) {
              for (let i = 0; i < this.selectRowsData.length; i++) {
                  if (this.dataList[j].key == this.selectRowsData[i]) {
                      lasRes.push(this.dataList[j]);
                  }
              }
          }
          return lasRes.length > 0?false:true;
      }
  },

    methods: {



        updateFLC(){
            let lasRes =[];
            for(let j=0;j<this.dataList.length;j++){
                for(let i=0;i<this.selectRowsData.length;i++) {
                    if(this.dataList[j].key==this.selectRowsData[i]){
                        lasRes.push(this.dataList[j]);
                    }
                }
            }

            let scuess="0";
            this.loading = true;
            for(let i=0;i<lasRes.length;i++) {
                let par = {
                    deviceId: lasRes[i].deviceid,
                    gwcId: lasRes[i].gwcid,
                    sysStat: lasRes[i].startstop,
                    person: "",
                    comment: "",
                };
                updateFLC(par)
                    .then((res) => {
                        let {data} = res;
                        if (!data) {
                            scuess = "1";
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        scuess = "1";
                    });
            }
            this.loading = false;
            if(scuess=="0"){
                this.$message.info(this.$t('controller.a16'));
                this.$emit('refresh',"");
            }else {
                this.$error({
                    title: this.$t('controller.a17'),
                    content: this.$t('controller.a17'),
                    centered: true,
                }
                );
            }
        },

    }

};
</script>
<style scoped>
#runStatusTable {
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 40px;
  white-space: nowrap;
  overflow: hidden;
}
table {
  width: 100%;
  border-left: 1px solid #dedfdf;
  border-right: 1px solid #dedfdf;
}
</style>